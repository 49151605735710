const isValidDate = (date) => {
  return !isNaN(new Date(date).getTime());
};

const isDateInRange = (dateToCheck, rangeArray) => {
  const startDate = rangeArray[0]
  const endDate = rangeArray[1]
  if (!isValidDate(dateToCheck) || !isValidDate(startDate) || !isValidDate(endDate)) {
    return false;
  }
  const checkDate = new Date(dateToCheck);
  const start = new Date(startDate);
  const end = new Date(endDate);
  return checkDate >= start && checkDate <= end;
};

export { isValidDate, isDateInRange };
